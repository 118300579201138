import React, { createContext, useState, useEffect } from "react";
// import { apiRouterCall } from "../api/services";
import axios from "axios";
import { calculateTimeLeft } from "../utils";
import { apiRouterCall } from "../api/services";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("admin_token", accessToken);
    axios.defaults.headers.common["Authorization"] = `${accessToken}`;
  } else {
    sessionStorage.removeItem("admin_token");
    sessionStorage.removeItem("admin_user_id");
    delete axios.defaults.headers.common["Authorization"];
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("admin_token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [coinList, setCoinList] = useState([]);
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [settings, setSettings] = useState({});
  const [adminProfile, setAdminProfile] = useState({});
  //function to get user profile and walet
  const getAdminSettingsHandler = async (user_id) => {
    try {
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "settings",
        params: {
          admin_user_id: user_id,
        },
      });

      if (response.status === 200) {
        setSettings(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAdminProfile = async () => {
    try {
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "adminProfile",
      });
      // console.log("response----", response);
      if (response.data.responseCode === 200) {
        setAdminProfile(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function to get the tokenList
  const getTokenListHandler = async () => {
    try {
      const response = await apiRouterCall({
        endPoint: "tokenList",
        method: "POST",
        data: {
          admin_user_id: window.sessionStorage.getItem("admin_user_id"),
        },
      });
      if (response.status === 200) {
        setCoinList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //context data object that has been passed to the data layer of the application
  let data = {
    timeLeft,
    settings,
    coinList,
    adminProfile,
    userLoggedIn: isLogin,
    getAdminSettingsHandler: (data) => getAdminSettingsHandler(data),
    getTokenListHandler: () => getTokenListHandler(),
    setEndtime: (data) => setEndtime(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("admin_user_id")) {
      getAdminSettingsHandler(window.sessionStorage.getItem("admin_user_id"));
      getTokenListHandler();
      getAdminProfile();
    }
    return;
  }, [window.sessionStorage.getItem("admin_user_id")]); // eslint-disable-line

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
