export const baseURL = "https://stgapi-exchange.tarality.io"; // staging_url
// export const baseURL = "https://stgapi.tarality.io"; // staging_url

export const socket_url = "https://socket.tarality.io";

let version = "api/v1"; //v0
let version_0 = "api";
let p2p = `${version}/p2p`;

const apiConfigs = {
  login: `${baseURL}/${version}/admin/login`,
  settings: `${baseURL}/${version_0}/settings`,
  uploadImageFile: `${baseURL}/${version}/user/uploadImageFile`,
  adminProfile: `${baseURL}/${version}/admin/profile`,
  //----KYC----//
  kycList: `${baseURL}/${version}/admin/listKyc`,
  viewKyc: `${baseURL}/${version}/admin/viewKyc`,
  acceptReject: `${baseURL}/${version}/admin/acceptReject`,

  //----TARAL EARN_DROP----//
  coins: `${baseURL}/${version}/admin/coins`,
  addPlan: `${baseURL}/${version}/admin/addPlan`,
  listPlan: `${baseURL}/${version}/admin/listPlan`,
  viewPlan: `${baseURL}/${version}/admin/viewPlan`,
  deletePlan: `${baseURL}/${version}/admin/deletePlan`,
  planParticipants: `${baseURL}/${version}/admin/planParticipants`,

  //----TARAL BANKING----//
  listCoin: `${baseURL}/${version}/admin/listCoin`,
  enableDisableCBanking: `${baseURL}/${version}/admin/enableDisableCBanking`,
  listCBIList: `${baseURL}/${version}/admin/listCBIList`,
  editCBIPlan: `${baseURL}/${version}/admin/editCBIPlan`,
  allUserCBIPlanList: `${baseURL}/${version}/admin/allUserCBIPlanList`,

  //----USER MANAGEMENT----//
  alluser: `${baseURL}/${version_0}/alluser`,
  listBank: `${baseURL}/${version}/admin/listBank`,
  viewBank: `${baseURL}/${version}/admin/viewBank`,
  acceptRejectBank: `${baseURL}/${version}/admin/acceptRejectBank`,

  //----FAST TRADE----//
  tokenList: `${baseURL}/${version_0}/gettoken`,
  updatecrptosetting: `${baseURL}/${version_0}/updatecrptosetting`,
  fasttradewallet: `${baseURL}/${version_0}/fasttradewallet`,
  updatefasttradewallet: `${baseURL}/${version_0}/updatefasttradewallet`,

  //----COIN AND TOKEN----//
  addtoken: `${baseURL}/${version_0}/addtoken`,
  getcontract: `${baseURL}/${version_0}/getcontract`,
  updatedepositwithdrawsetting: `${baseURL}/${version_0}/updatedepositwithdrawsetting`,
  pairedCurrency: `${baseURL}/${version_0}/paired_currency`,
  addTokenAdmin: `${baseURL}/${version_0}/addTokenAdmin`,

  //----BNPL----//
  addNewPlan: `${baseURL}/${version}/admin/addNewPlan`,
  planList: `${baseURL}/${version}/admin/planList`,
  activeInActivePlan: `${baseURL}/${version}/admin/activeInActivePlan`,
  getPlan: `${baseURL}/${version}/admin/getPlan`,
  editPlan: `${baseURL}/${version}/admin/editPlan`,
  getAllUserBNPlanList: `${baseURL}/${version}/admin/getAllUserBNPlanList`,

  //---Announcement--//
  createAnnouncement: `${baseURL}/${version}/announcement/createAnnouncement`,
  deleteAnnouncement: `${baseURL}/${version}/announcement/deleteAnnouncement`,
  announcmentList: `${baseURL}/${version}/announcement/getAnnouncementList`,

  //------COLD & HOT WALLET----//
  coldwallet: `${baseURL}/${version_0}/coldwallet`,
  hotwallet: `${baseURL}/${version_0}/hotwallet`,
  updatecoldwallet: `${baseURL}/${version_0}/updatecoldwallet`,
  updatehotwallet: `${baseURL}/${version_0}/updatehotwallet`,
  capture_all_wallet: `${baseURL}/${version_0}/capture_all_wallet`,
  delete_wallet: `${baseURL}/${version_0}/delete_wallet`,
  get_capture_hits: `${baseURL}/${version_0}/get-capture-hits`,
  user_wallet_capture: `${baseURL}/${version_0}/user_wallet_capture`,

  //------REFERRAL----//
  updateSettings: `${baseURL}/${version_0}/updatesettings`,
  getRefferalNotKYC: `${baseURL}/${version_0}/user/get-referal-notkyc`,
  getRefferalList: `${baseURL}/${version_0}/user/get-all-referal`,
  admin_send_capture: `${baseURL}/${version_0}/admin_send_capture`,
  getairdroplist: `${baseURL}/${version_0}/getairdroplist`,
  referralList: `${baseURL}/${version}/admin/referralList`,
  referralNotKycList: `${baseURL}/${version}/admin/referralNotKycList`,
  airDropList: `${baseURL}/${version}/admin/airDropList`,

  //-----Withdraw Request Manage-------//
  withdraw_history_admin_side: `${baseURL}/${version_0}/withdraw_history_admin_side`,
  withdraw_history_request: `${baseURL}/${version_0}/withdraw_history_request`,
  withdraw_crypto: `${baseURL}/${version_0}/admin/withdraw-crypto`,
  withdraw_history_update: `${baseURL}/${version_0}/withdraw_history_update`,
  withdrawBTCCoin_wallet: `${baseURL}/${version_0}/withdrawBTCCoin_wallet`,
  withdrawSOLCoin_wallet: `${baseURL}/${version_0}/withdrawSOLCoin_wallet`,
  internalPaymenttransfer: `${baseURL}/${version_0}/internalPaymenttransfer`,

  //-----Admin Bank -------//
  listAdminBank: `${baseURL}/${version}/admin/listAdminBank`,
  activeInactiveBank: `${baseURL}/${version}/admin/activeInactiveBank`,
  deleteAdminBank: `${baseURL}/${version}/admin/deleteAdminBank`,
  bankList: `${baseURL}/${version_0}/banking/get-bank`,
  addAdminBank: `${baseURL}/${version}/admin/addAdminBank`,

  // ------paired_currency -----//
  paired_currency: `${baseURL}/${version_0}/paired_currency`,

  //----INR Requets----//
  depositRequestList: `${baseURL}/${version}/admin/depositRequestList`,
  viewDeposit: `${baseURL}/${version}/admin/viewDeposit`,
  acceptRejectDeposit: `${baseURL}/${version}/admin/acceptRejectDeposit`,

  inrRequestHistory: `${baseURL}/${version}/admin/inrRequestHistory`,
  viewInrRequest: `${baseURL}/${version}/admin/viewInrRequest`,
  getInrFund: `${baseURL}/${version}/admin/getInrFund`,
  acceptRejectInrWithdraw: `${baseURL}/${version}/admin/acceptRejectInrWithdraw`,

  //----Lock Fund----//
  allUsers: `${baseURL}/${version}/admin/allUsers`,
  userWallets: `${baseURL}/${version}/admin/userWallets`,
  lockFund: `${baseURL}/${version}/admin/lockFund`,
  lockFundList: `${baseURL}/${version}/admin/lockFundList`,
  viewLockFund: `${baseURL}/${version}/admin/viewLockFund`,
  editLockFund: `${baseURL}/${version}/admin/editLockFund`,

  //----Maintenance Mode----//
  maintenanceList: `${baseURL}/${version}/maintenance/getMaintenanceList`,
  maintenanceUpdate: `${baseURL}/${version}/maintenance/createMaintenance`,

  //----Order Report----//
  getAllOrder: `${baseURL}/${version_0}/get-all-order`,
  cancelOrder: `${baseURL}/${version_0}/cancle-order`,
  executedOrder: `${baseURL}/${version_0}/get-all-orderHistory`,
  createMultipleOrders: `${baseURL}/${version_0}/create-multiple-order`,

  //----Order Report----//
  sellComissions: `${baseURL}/${version_0}/sell-commission`,
  addfundtouser: `${baseURL}/${version_0}/addfundtouser`,
  fundtranferhistory: `${baseURL}/${version_0}/fundtranfer_history`,
  get_user_wallets: `${baseURL}/${version_0}/get-user-wallets`,
  deposite_coin_history: `${baseURL}/${version_0}/deposite-coin-history`,
  minimum_deposite_coin_history: `${baseURL}/${version_0}/minimum-deposite-coin-history`,

  admin_Side_deposite_history: `${baseURL}/${version_0}/admin_Side_deposite_history`,
  get_symbol_detail: `${baseURL}/${version_0}/get-symbol-detail`,

  //----Subadmin Management----//
  addSubAdmin: `${baseURL}/${version}/admin/addSubAdmin`,
  subAdminList: `${baseURL}/${version}/admin/subAdminList`,
  deleteSubAdmin: `${baseURL}/${version}/admin/deleteSubAdmin`,
  viewSubAdmin: `${baseURL}/${version}/admin/viewSubAdmin`,
  editSubAdmin: `${baseURL}/${version}/admin/editSubAdmin`,

  // ------paired_currency -----//
  paired_currency: `${baseURL}/${version_0}/paired_currency`,

  // ------user_management -----//
  getkyclist: `${baseURL}/${version_0}/getkyclist`,
  userActivity: `${baseURL}/${version_0}/get-user-activity`,
  accountActivity: `${baseURL}/${version_0}/activity-log`,
  depositHistory: `${baseURL}/${version_0}/admin-deposit-history`,
  minimumDepositHistory: `${baseURL}/${version_0}/admin-minimum-deposit-history`,
  withdrawHistory: `${baseURL}/${version_0}/admin-withdraw-history`,
  tradeHistory: `${baseURL}/${version_0}/admin-trade-history`,
  maintenanceSettings: `${baseURL}/${version}/setting/maintenanceSettings`,
  getMaintenance: `${baseURL}/${version}/setting/getMaintenance`,
  funds: `${baseURL}/${version_0}/user-fund-history`,
  orders: `${baseURL}/${version_0}/admin-order-history`,
  modifyUserProfile: `${baseURL}/${version_0}/modify_user_profile`,
  permanentDeleteUser: `${baseURL}/${version_0}/permanent_delete_user`,
  fastTradeHistory: `${baseURL}/${version_0}/fast-trade-history`,
  getLockFundWalletList: `${baseURL}/${version}/admin/getLockFundWalletList`,
  bnplHistoryList: `${baseURL}/${version}/admin/bnplHistoryList`,
  allLeverageHistory: `${baseURL}/${version}/admin/allLeverageHistory`,
  earnDropHistoryList: `${baseURL}/${version}/admin/earnDropHistoryList`,
  allUserCBIPlan: `${baseURL}/${version}/admin/allUserCBIPlan`,

  //------help and support -----//
  addCategory: `${baseURL}/${version}/admin/addCategory`,
  listCategory: `${baseURL}/${version}/admin/listCategory`,
  activeInactiveCategory: `${baseURL}/${version}/admin/activeInactiveCategory`,
  deleteCategory: `${baseURL}/${version}/admin/deleteCategory`,
  queryTicketList: `${baseURL}/${version}/admin/queryTicketList`,
  ticketDetails: `${baseURL}/${version}/admin/ticketDetails`,
  reply: `${baseURL}/${version}/admin/reply`,
  updateTicket: `${baseURL}/${version}/admin/updateTicket`,

  //------payment options------//
  moduleList: `${baseURL}/${version}/admin/moduleList`,
  listCoin: `${baseURL}/${version}/admin/listCoin`,
  setCoin: `${baseURL}/${version}/admin/setCoin`,
  listCoinSettings: `${baseURL}/${version}/admin/listCoinSettings`,
  deleteCoinSettings: `${baseURL}/${version}/admin/deleteCoinSetting`,
  activeInActiveCoinSetting: `${baseURL}/${version}/admin/activeInActiveCoinSetting`,

  //------analytics------//
  totalLockAmountList: `${baseURL}/${version}/admin/totalLockAmountList`,
  totalTaralAmountList: `${baseURL}/${version}/admin/totalTaralAmountList`,
  allUserLockAmountList: `${baseURL}/${version}/admin/allUserLockAmountList`,
  allWalletSumBalance: `${baseURL}/${version}/admin/allWalletSumBalance`,
  allWalletLockFund: `${baseURL}/${version}/admin/allWalletLockFund`,
  allUserbalanceLogList: `${baseURL}/${version}/admin/allUserbalanceLogList`,

  //------dashboard------//
  totalPortfolioFund: `${baseURL}/${version}/admin/totalPortfolioFund`,
  // order book management
  addOrderPair: `${baseURL}/${version}/admin/addOrderPair`,
  getOrderPair: `${baseURL}/${version}/admin/getOrderPair`,
  activeInActiveOrderPair: `${baseURL}/${version}/admin/activeInActiveOrderPair`,
  updateOrderPair: `${baseURL}/${version}/admin/updateOrderPair`,

    //------P2P----------//
  p2pOrderHistoryAdmin: `${baseURL}/${p2p}/p2p-order-history-admin`,
  p2pOrderActionAdmin: `${baseURL}/${p2p}/p2p-order-actions-admin`,

};

export default apiConfigs;
