import { lazy } from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/Login")),
  },

  // Dashboard -- //
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/dashboard")),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard/analytics",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/dashboard/analytics")),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard/statistics",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/dashboard/statistics")),
  },

  // User ---//

  {
    exact: true,
    guard: true,
    path: "/user-management-all",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/userManagement/alluser")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-management-blocked",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/userManagement/blocked")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-detail-view",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/userManagement/userView")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-management-bank-accounts",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/userManagement/bankAccounts")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-management-bank-accounts-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/userManagement/bankAccounts/ViewBankDetails")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/user-management-lock-funds",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/userManagement/lockedBalances")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/user-management-add-lock-funds",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/userManagement/lockedBalances/addFund")
    ),
  },
  //KYC ---//
  {
    exact: true,
    guard: true,
    path: "/kyc-management-pending",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/kycManagement/pending")),
  },
  {
    exact: true,
    guard: true,
    path: "/kyc-management-approved",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/kycManagement/approved")),
  },
  {
    exact: true,
    guard: true,
    path: "/kyc-management-rejected",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/kycManagement/rejected")),
  },
  {
    exact: true,
    guard: true,
    path: "/kyc-detailed-view",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/kycManagement/DetailedView")),
  },

  //Taral Earn/Drop

  {
    exact: true,
    guard: true,
    path: "/taral-earn-drop",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/taralEarnDrop")),
  },
  {
    exact: true,
    guard: true,
    path: "/tara-earn-drop-add-plan",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/taralEarnDrop/addPlan")),
  },

  {
    exact: true,
    guard: true,
    path: "/tara-earn-drop-view-plan",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/taralEarnDrop/viewPlan")),
  },

  {
    exact: true,
    guard: true,
    path: "/coin-token-add-token",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/coinToken/addToken")),
  },

  {
    exact: true,
    guard: true,
    path: "/coin-token-add-new-token",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/coinToken/addToken/AddToken")),
  },

  {
    exact: true,
    guard: true,
    path: "/coin-token-crypto-settings",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/coinToken/cryptoSettings/SetCrypto")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/coin-token-crypto-limit/cap",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/coinToken/cryptoLimit&Cap/index")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/coin-token-limit-capping",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/coinToken/cryptoLimit&Cap/LimitCapping")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/coin-token-deposit/withdraw",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/coinToken/depositWithdraw")),
  },

  {
    exact: true,
    guard: true,
    path: "/coin-token-create-pair",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/coinToken/createPairing")),
  },
  {
    exact: true,
    guard: true,
    path: "/coin-token-set-pair",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/coinToken/setPairing")),
  },
  {
    exact: true,
    guard: true,
    path: "/coin-token-fast-trade-tokens",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/coinToken/fastTradeToken")),
  },

  //Wallet settings
  {
    exact: true,
    guard: true,
    path: "/wallet-settings-fast-trade-wallet",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/walletSettings/fastTradeWallet")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-settings-cold-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/walletSettings/coldWallet")),
  },
  {
    exact: true,
    guard: true,
    path: "/coin-token-symbol-balance",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/coinToken/symbolBalance")),
  },

  {
    exact: true,
    guard: true,
    path: "/wallet-settings-hot-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/walletSettings/hotWallet")),
  },

  {
    exact: true,
    guard: true,
    path: "/wallet-settings-capture-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/walletSettings/captureHistory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-settings-user-capture-fund-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/walletSettings/userCaptureHistory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-settings-admin-send-capture",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/walletSettings/adminSendCapture")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-settings-withdraw-deposit-fees",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/walletSettings/withDepoFees")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-settings-withdraw-trade-fees",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/walletSettings/tradeFee")),
  },

  //Withdraw Management
  {
    exact: true,
    guard: true,
    path: "/withdraw-inr-pending-requests",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/withdrawManagement/PendingINR")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/order-book-management",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/orderBookManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-order-pair",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/orderBookManagement/AddOrderPair")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/withdraw-inr-requests-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/withdrawManagement/PendingINR/Details")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/static-data-announcements",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/staticManagement/Announcement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/static-data-announcements/add-data",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/staticManagement/AddData")),
  },
  {
    exact: true,
    guard: true,
    path: "/withdraw-coin-pending-requests",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/withdrawManagement/PendingCoin")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/withdraw-verified-requests",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/withdrawManagement/withdraw/approved")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/withdraw-rejected-requests",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/withdrawManagement/withdraw/rejected")
    ),
  },

  //admin-bank-account
  {
    exact: true,
    guard: true,
    path: "/admin-bank-account",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/adminBankAccount")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-admin-bank-account",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/adminBankAccount/AddBank")),
  },

  //deposit-inr
  {
    exact: true,
    guard: true,
    path: "/inr-deposit-request",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/deposits")),
  },

  {
    exact: true,
    guard: true,
    path: "/inr-deposit-request-view",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/deposits/details")),
  },

  //referral Management
  {
    exact: true,
    guard: true,
    path: "/referral-management",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/referralManagement")),
  },

  //maintenance mode

  {
    exact: true,
    guard: true,
    path: "/maintenance-mode",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/maintenanceMode")),
  },

  //order-reports-spot
  {
    exact: true,
    guard: true,
    path: "/order-report-open-buy",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/orderReports/orders/buy")),
  },
  {
    exact: true,
    guard: true,
    path: "/order-report-open-sell",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/orderReports/orders/sell")),
  },
  {
    exact: true,
    guard: true,
    path: "/order-report-executed-order",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/orderReports/orders/executed")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/order-report-cancelled-buy-order",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/orderReports/cancelled/buy")),
  },
  {
    exact: true,
    guard: true,
    path: "/order-report-cancelled-sell-order",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/orderReports/cancelled/sell")),
  },
  {
    exact: true,
    guard: true,
    path: "/order-report-create-order",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/orderReports/create")),
  },
    //p2p
    {
      exact: true,
      guard: true,
      path: "/p2p-report-history",
      layout: DashboardLayout,
      component: lazy(() => import("../views/pages/p2p/P2PHistory")),
    },
    {
      exact: true,
      guard: true,
      path: "/p2p-report-action",
      layout: DashboardLayout,
      component: lazy(() => import("../views/pages/p2p/P2PAction")),
    },
  

  //finance
  {
    exact: true,
    guard: true,
    path: "/finance-sell-commission",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/finance/sellCommission")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-fund-to-user",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/finance/addFundToUser")),
  },
  {
    exact: true,
    guard: true,
    path: "/withdrawalfees",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/finance/withdrawalFees")),
  },
  {
    exact: true,
    guard: true,
    path: "/userwalletfund",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/finance/userWalletFund")),
  },
  {
    exact: true,
    guard: true,
    path: "/coin-deposit-history",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/finance/coinDepositHistory")),
  },
  {
    exact: true,
    guard: true,
    path: "/minimum-coin-deposit-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/finance/minimumCoinDepositHistory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/inr-deposit-history",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/finance/inrDeposithistory")),
  },
  {
    exact: true,
    guard: true,
    path: "/coin-withdraw-history",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/finance/coinWithdrawHistory")),
  },
  {
    exact: true,
    guard: true,
    path: "/inr-withdraw-history",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/finance/inrWithdrawHistory")),
  },
  {
    exact: true,
    guard: true,
    path: "/symbol-balance-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../views/pages/finance/symbolbalanceDetails")
    ),
  },
  //subadmin
  {
    exact: true,
    guard: true,
    path: "/subadmin-management",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/subadminManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/create-subadmin",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/subadminManagement/create")),
  },

  //help and support
  {
    exact: true,
    guard: true,
    path: "/help-and-support-subjects",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/helpAndSupport/subjects")),
  },
  {
    exact: true,
    guard: true,
    path: "/help-and-support-tickets",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/helpAndSupport")),
  },
  {
    exact: true,
    guard: true,
    path: "/help-and-support-conversation-view",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/helpAndSupport/Details")),
  },

  //payment-options
  {
    exact: true,
    guard: true,
    path: "/payment-option-management",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/paymentOptions")),
  },
  {
    exact: true,
    guard: true,
    path: "/payment-option-module-view",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/paymentOptions/moduleView")),
  },

  {
    exact: true,
    path: "*",
    component: lazy(() => import("../views/errors/NotFound")),
  },

  {
    component: () => <Navigate to="/404" />,
  },
];
