import { Box, Divider, IconButton } from "@mui/material";
import { React, useState, useContext } from "react";
// import { useNavigate } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ThemeSwitchButton from "../../../components/themeSwitchButton";
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from "../../../context/auth";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import toast from "react-hot-toast";
import {useNavigate } from "react-router";

export default function RightOptions({ themeSettings, sx }) {
  const [openLogout, setOpenLogout] = useState(false);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const logoutHandler = () => {
    auth.userLogIn(false, null);
    toast.success("You have successfully logged out!");
    navigate("/");
  };


  return (
    <Box sx={sx}>
      <IconButton onClick={() => setOpenLogout(true)}>
        <LogoutIcon />
      </IconButton>
      <IconButton>
        <AccountCircleIcon />
      </IconButton>

      <Divider
        orientation="vertical"
        flexItem
        sx={{
          backgroundColor:
            themeSettings.settings.theme === "DARK" ? "#b9b3b3" : "#fff",
          margin: "0px 4px",
        }}
      />
      <ThemeSwitchButton />
      {openLogout && (
        <ConfirmationModal
          open={openLogout}
          title="Logout!"
          description="Are you sure want to logout?"
          onClick={logoutHandler}
          close={() => setOpenLogout(false)}
        />
      )}
    </Box>
  );
}
