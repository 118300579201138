import { ThemeProvider } from "@mui/material";
import { useContext } from "react";
import { createAppTheme } from "./theme";
import ThemeContext from "./context/themeContext";
import { routes } from "./routes";
import { RenderRoutes } from "./routes/renderRoutes";
import { Toaster } from "react-hot-toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  const themeSettings = useContext(ThemeContext);
  const theme = createAppTheme({
    theme: themeSettings.settings.theme,
  });
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <RenderRoutes data={routes} />
          <Toaster
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            // theme="light"
            theme={theme.palette.type}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
