import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Drawer, Hidden, List, ListSubheader } from "@mui/material";
import NavItem from "./NavItem";
import { styled } from "@mui/system";
import ThemeContext from "../../../context/themeContext";
import { colors } from "../../../assets/colors";
import { useLocation } from "react-router";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PeopleIcon from "@mui/icons-material/People";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TollIcon from "@mui/icons-material/Toll";
import TokenIcon from "@mui/icons-material/Token";
import SettingsIcon from "@mui/icons-material/Settings";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import IsoIcon from "@mui/icons-material/Iso";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import CampaignIcon from "@mui/icons-material/Campaign";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import WalletIcon from "@mui/icons-material/Wallet";
import FilterBAndWIcon from "@mui/icons-material/FilterBAndW";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LockIcon from "@mui/icons-material/Lock";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PolylineIcon from "@mui/icons-material/Polyline";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import GamesIcon from "@mui/icons-material/Games";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { AuthContext } from "../../../context/auth";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TopicIcon from "@mui/icons-material/Topic";
import BugReportIcon from "@mui/icons-material/BugReport";
import PixIcon from "@mui/icons-material/Pix";

const MobileDrawer = styled(Drawer)(({ theme }) => ({
  width: 272,
  "& .MuiPaper-root": {
    borderRadius: "5px",
  },
}));

const DesktopDrawer = styled(Drawer)(({ theme }) => ({
  top: "95px",
  width: "200px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,

  "& .MuiPaper-root": {
    borderRadius: "0px",
    top: 72,
  },
}));

const SideMenuBox = styled(Box)(({ theme }) => ({
  width: "200px",
  // backgroundColor: theme.palette.primary.main,
  // color: theme.palette.secondary.main,
  "& .MuiCollapse-wrapperInner": {
    marginLeft: "25px",
  },
}));

function renderNavItems({ items, pathname, depth = 0, onMobileClose }) {
  return (
    <List disablePadding onClick={onMobileClose}>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, depth, onMobileClose }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, onMobileClose }) {
  const key = item.title + depth;

  if (item.items) {
    // const open = matchPath(pathname, {
    //   path: item.href,
    //   exact: false,
    // });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={false}
        title={item.title}
        onMobileClose={onMobileClose}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        onMobileClose={onMobileClose}
      />
    );
  }

  return acc;
}

const sections = [
  {
    items: [
      {
        id: "dashboard",
        title: "Dashboard",
        icon: <DashboardIcon />,
        href: "/dashboard",
        items: [
          {
            title: "Statistics",
            icon: <DataUsageIcon />,
            href: "/dashboard/statistics",
          },
          {
            title: "Analytics",
            icon: <AnalyticsIcon />,
            href: "/dashboard/analytics",
          },
        ],
      },
      {
        id: "userManagement",
        title: "User Management",
        icon: <GroupIcon />,
        href: "/user-management",
        items: [
          {
            title: "All User",
            icon: <PeopleIcon />,
            href: "/user-management-all",
          },
          {
            title: "Blocked User",
            icon: <PersonOffIcon />,
            href: "/user-management-blocked",
          },
          {
            title: "Bank Accounts",
            icon: <AccountBalanceIcon />,
            href: "/user-management-bank-accounts",
          },
          {
            title: "Lock Funds",
            icon: <LockIcon />,
            href: "/user-management-lock-funds",
          },
        ],
      },

      {
        id: "kycManagement",
        title: "KYC Management",
        icon: <HowToRegIcon />,
        href: "/kyc-management",
        items: [
          {
            title: "Pending KYC",
            icon: <PendingActionsIcon />,
            href: "/kyc-management-pending",
          },
          {
            title: "Rejected KYC",
            icon: <PersonOffIcon />,
            href: "/kyc-management-rejected",
          },
          {
            title: "Approved KYC",
            icon: <AdminPanelSettingsIcon />,
            href: "/kyc-management-approved",
          },
        ],
      },

      {
        id: "bankManagement",
        title: "Admin Bank Account",
        icon: <AccountBalanceIcon />,
        href: "/admin-bank-account",
      },
      {
        id: "earnDropManagement",
        title: "Taral Earn/Drop",
        icon: <TollIcon />,
        href: "/taral-earn-drop",
      },

      {
        id: "coinAndToken",
        title: "Coin & Token",
        icon: <TokenIcon />,
        items: [
          {
            title: "Fast Trade Tokens",
            icon: <TrendingUpIcon />,
            href: "/coin-token-fast-trade-tokens",
          },
          {
            title: "Add Token",
            icon: <TokenIcon />,
            href: "/coin-token-add-token",
          },
          {
            title: "Crypto Settings",
            icon: <SettingsIcon />,
            href: "/coin-token-crypto-settings",
          },
          {
            title: "Crypto Limit/Capping",
            icon: <ProductionQuantityLimitsIcon />,
            href: "/coin-token-crypto-limit/cap",
          },

          {
            title: "Deposit/Withdrawal",
            icon: <IsoIcon />,
            href: "/coin-token-deposit/withdraw",
          },
          {
            title: "Create Pairing",
            icon: <AddBoxIcon />,
            href: "/coin-token-create-pair",
          },
          {
            title: "Set Pairing",
            icon: <SettingsEthernetIcon />,
            href: "/coin-token-set-pair",
          },
        ],
      },
      {
        id: "walletSettings",
        title: "Wallet Settings",
        icon: <AccountBalanceWalletIcon />,
        items: [
          {
            title: "Fast Trade Wallet",
            icon: <TrendingUpIcon />,
            href: "/wallet-settings-fast-trade-wallet",
          },
          {
            title: "Cold Wallet",
            icon: <WalletIcon />,
            href: "/wallet-settings-cold-wallet",
          },
          {
            title: "Hot Wallet",
            icon: <WalletIcon />,
            href: "/wallet-settings-hot-wallet",
          },
          {
            title: "Capture History",
            icon: <WalletIcon />,
            href: "/wallet-settings-capture-history",
          },
          {
            title: "User Capture History",
            icon: <WalletIcon />,
            href: "/wallet-settings-user-capture-fund-history",
          },
          {
            title: "Admin Send Capture",
            icon: <WalletIcon />,
            href: "/wallet-settings-admin-send-capture",
          },
          {
            title: "With/Depo Fees",
            icon: <FilterBAndWIcon />,
            href: "/wallet-settings-withdraw-deposit-fees",
          },
          {
            title: "Trade Fees",
            icon: <FilterBAndWIcon />,
            href: "/wallet-settings-withdraw-trade-fees",
          },
        ],
      },

      {
        id: "inrDeposit",
        title: "INR Deposits",
        icon: <CurrencyRupeeIcon />,
        href: "/inr-deposit-request",
      },
      {
        id: "withdrawManagement",
        title: "Withdraw Manag.",
        icon: <CurrencyExchangeIcon />,
        items: [
          {
            title: "INR Requests",
            icon: <RequestPageIcon />,
            href: "/withdraw-inr-pending-requests",
          },
          {
            title: "Coin Pending Req.",
            icon: <RequestPageIcon />,
            href: "/withdraw-coin-pending-requests",
          },
          {
            title: "Verified Withdraw",
            icon: <AssuredWorkloadIcon />,
            href: "/withdraw-verified-requests",
          },
          {
            title: "Rejected Withdraw",
            icon: <DoDisturbOffIcon />,
            href: "/withdraw-rejected-requests",
          },
        ],
      },
      {
        id: "orderReport",
        title: "Order Report",
        icon: <SummarizeIcon />,
        items: [
          {
            title: "Open Buy",
            icon: <CallMadeIcon />,
            href: "/order-report-open-buy",
          },
          {
            title: "Open Sell",
            icon: <CallReceivedIcon />,
            href: "/order-report-open-sell",
          },
          {
            title: "Executed Order",
            icon: <AssuredWorkloadIcon />,
            href: "/order-report-executed-order",
          },
          {
            title: "Cancelled Buy Order",
            icon: <DoDisturbOffIcon />,
            href: "/order-report-cancelled-buy-order",
          },
          {
            title: "Cancelled Sell Order",
            icon: <DoDisturbOffIcon />,
            href: "/order-report-cancelled-sell-order",
          },
          {
            title: "Create Order",
            icon: <PolylineIcon />,
            href: "/order-report-create-order",
          },
        ],
      },
      {
        id: "p2porderReport",
        title: "P2P Report",
        icon: <SummarizeIcon />,
        items: [
          {
            title: "P2P History",
            icon: <CallMadeIcon />,
            href: "/p2p-report-history",
          },
          {
            title: "P2P Action",
            icon: <CallReceivedIcon />,
            href: "/p2p-report-action",
          },
        ],
      },
      {
        id: "finanaceReports",
        title: "Finance Reports",
        icon: <EqualizerIcon />,
        items: [
          {
            title: "Sell Commission",
            icon: <GamesIcon />,
            href: "/finance-sell-commission",
          },
          {
            title: "Add Fund to User",
            icon: <TrendingUpIcon />,
            href: "/add-fund-to-user",
          },
          {
            title: "Withdrawal Fees",
            icon: <TrendingUpIcon />,
            href: "/withdrawalfees",
          },
          {
            title: "User Wallet Fund",
            icon: <TrendingUpIcon />,
            href: "/userwalletfund",
          },
          {
            title: "Coin Deposit History",
            icon: <TrendingUpIcon />,
            href: "/coin-deposit-history",
          },
          {
            title: "Minimum Coin Deposit History",
            icon: <TrendingUpIcon />,
            href: "/minimum-coin-deposit-history",
          },
          {
            title: "INR Deposit History",
            icon: <TrendingUpIcon />,
            href: "/inr-deposit-history",
          },
          {
            title: "Coin Withdraw History",
            icon: <TrendingUpIcon />,
            href: "/coin-withdraw-history",
          },
          {
            title: "INR Withdraw History",
            icon: <TrendingUpIcon />,
            href: "/inr-withdraw-history",
          },
          {
            title: "Symbol Balance Details",
            icon: <TrendingUpIcon />,
            href: "/symbol-balance-details",
          },
        ],
      },
      {
        id: "staticDataManagement",
        title: "Static Data Settings",
        icon: <EqualizerIcon />,
        items: [
          {
            title: "Announcements",
            icon: <CampaignIcon />,
            href: "/static-data-announcements",
          },
        ],
      },
      {
        id: "supportManagement",
        title: "Help & Support",
        icon: <SupportAgentIcon />,
        items: [
          {
            title: "Subjects",
            icon: <TopicIcon />,
            href: "/help-and-support-subjects",
          },
          {
            title: "Tickets",
            icon: <BugReportIcon />,
            href: "/help-and-support-tickets",
          },
        ],
      },
      {
        id: "subAdminManagement",
        title: "Subadmin Management",
        icon: <SupervisorAccountIcon />,
        href: "/subadmin-management",
      },
      {
        id: "orderBookManagement",
        title: "Order Book Management",
        icon: <SummarizeIcon />,
        href: "/order-book-management",
      },
      {
        id: "maintenanceMode",
        title: "Maintenance Mode",
        icon: <ModeStandbyIcon />,
        href: "/maintenance-mode",
      },
      {
        id: "referralManagement",
        title: "Referral Management",
        icon: <Diversity2Icon />,
        href: "/referral-management",
      },
      {
        id: "paymentOptions",
        title: "Payment Options",
        icon: <PixIcon />,
        href: "/payment-option-management",
      },
    ],
  },
];

const NavBar = ({ onMobileClose, openMobile }) => {
  const route = useLocation();
  const themeSettings = useContext(ThemeContext);
  const { adminProfile } = useContext(AuthContext);

  const themeColors = {
    backgroundColor:
      themeSettings.settings.theme === "DARK"
        ? colors.darkSecondary
        : "#1d1d1d0a",
    color:
      colors[
        themeSettings.settings.theme === "DARK"
          ? colors.white
          : colors.darkPrimary
      ],
    boxShadow: "none",
  };

  //function to check the admin/subadmin accessible section
  const permissionPanels = useMemo(() => {
    if (adminProfile && adminProfile?.user_role === 2) {
      return sections;
    } else if (adminProfile && adminProfile?.user_role === 3) {
      const checkPermission =
        sections &&
        sections[0] &&
        sections[0]?.items?.filter((data) => {
          const check = adminProfile?.permissions[data?.id];

          if (check?.read || check?.write) {
            return data;
          }
        });
      return [
        {
          items: checkPermission,
        },
      ];
    } else {
      return [];
    }
  }, [adminProfile]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      // justifyContent="center"
      sx={themeColors}
      position="relative"
    >
      <Box pb={8.5}>
        <SideMenuBox>
          {permissionPanels?.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                img: section.img,
                items: section.items,
                pathname: route.pathname,
                state: section.tabview,
                onMobileClose: onMobileClose,
              })}
            </List>
          ))}
        </SideMenuBox>
      </Box>

      {/* Rest of your code */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        {openMobile && (
          <MobileDrawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            <Box
              p={2}
              height="100%"
              className="displaySpacebetween"
              flexDirection="column"
            >
              {content}
            </Box>
          </MobileDrawer>
        )}
      </Hidden>
      <Hidden mdDown>
        <DesktopDrawer anchor="left" open variant="persistent">
          {content}
        </DesktopDrawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
