import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
export default function AuthLayout({ children }) {
  return (
    <React.Fragment>
      <Box>{children}</Box>
    </React.Fragment>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.node,
};
