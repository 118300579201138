import React, { useContext } from "react";
import PropTypes from "prop-types";
import { IconButton, Toolbar, AppBar, Box } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import Logo from "../../../components/Logo";
// import { useNavigate } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeContext from "../../../context/themeContext";
import { colors } from "../../../assets/colors";
import RightOptions from "./RightOptions";

// import { AuthContext } from "../../../context/auth";
const TopBar = ({ onMobileNavOpen, isDashboard }) => {
  // const navigate = useNavigate();
  const themeSettings = useContext(ThemeContext);
  const themeColors = {
    backgroundColor:
      themeSettings.settings.theme === "DARK" ? colors.bgDark : colors.white,
    color:
      colors[
        themeSettings.settings.theme === "DARK"
          ? colors.white
          : colors.darkPrimary
      ],
    boxShadow: "none",
    borderRadius: "0px",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar component="nav" sx={themeColors}>
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isDashboard && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={onMobileNavOpen}
                  sx={{ mr: 2, display: { lg: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              )}

              <Logo
                style={{
                  width: "30%",
                  minWidth: "100px",
                  maxWidth: "150px",
                }}
              />
            </Box>

            <RightOptions
              themeSettings={themeSettings}
              sx={{
                display: { lg: "flex", sm: "none", md: "none", xs: "none" },
                alignItems: "center",
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
