import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Box } from "@mui/material";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  position: "relative",
  // background: "#f3f5f7",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  position: "relative",
  minHeight: "calc(100vh - 54px)",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  marginTop: "54px",
}));

const ContentContainer = styled(Box)({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const Content = styled(Box)(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "hidden",
  position: "relative",
  marginTop: "18px",
  // padding: "23px 25px 25px",
  padding: "10px",
  // [theme.breakpoints.down("md")]: {
  //   padding: "23px 25px 25px",
  // },
}));

export default function DashboardLayout({ children }) {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <React.Fragment>
      <Root>
        <TopBar
          onMobileNavOpen={() => setMobileNavOpen(true)}
          isDashboard={true}
        />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <Wrapper>
          <ContentContainer>
            <Content>{children}</Content>
          </ContentContainer>
        </Wrapper>
      </Root>
    </React.Fragment>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
