import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { socket_url } from "../api";
import { socket_access_token } from "../contants";

export const FlexLayerContext = createContext();

export default function FlexLayerProvider(props) {
  const [coinListData, setCoinListData] = useState([]);

  /*
  function to create a socket connection once 
  the user lands to the application 
  and also diconnect the same connection once the user leaves
  */
  useEffect(() => {
    const socket = io(socket_url, {
      auth: {
        token: socket_access_token,
      },
    });

    socket.on("connect", () => {
      console.log("Connected to the server!");
    });

    //emitter to get the cup
    socket.on("cmc_updated", (data) => {
      // console.log("data-as--cmc--", data);
      setCoinListData(data);
    });

    // Clean up socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  let data = {
    coinListData,
  };

  return (
    <FlexLayerContext.Provider value={data}>
      {props.children}
    </FlexLayerContext.Provider>
  );
}
